import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { BankAccount } from 'src/model/Bank';
import { OneTradingForm } from 'src/model/Form';
import { runBatch } from 'src/service/orderService';
import Card from './component/Card';
import Table from './component/Table';

type BatchState = {
  action: 'trading' | 'edit' | 'unsuspend' | 'suspend' | 'delete';
  base: 'usdt';
  quote: string;
  batchData: (OneTradingForm & { bankAccount?: BankAccount })[];
};

const TradingBatchConfirm = () => {
  const location = useLocation();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const state = location.state as BatchState | null;
  const [batchArray, setBatchArray] = useState<
    (OneTradingForm & {
      bankAccount?: BankAccount;
      status: string | null;
      message: string | null;
    })[]
  >();
  const [currentIdx, setCurrentIdx] = useState<number>();
  const [paused, setPaused] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const action = useMemo(() => state?.action, [state]);
  const [heading, textSubmit] = useMemo(
    () => [
      t(`tradingBatch.desc.batchHeading.${action}`),
      action === 'trading' || action === 'edit'
        ? t('tradingConfirm.act.saveAndOpen')
        : t('act.submit'),
    ],
    [action, t],
  );

  useEffect(() => {
    if (state === null) navigate(Page.Trading);
    else
      setBatchArray(
        state.batchData.map((v) => ({
          id: v.id,
          price: v.price,
          quote: v.quote,
          amount: v.amount,
          minTotal: v.minTotal,
          maxTotal: v.maxTotal,
          bankAccountId: v.bankAccountId,
          bankAccount: v.bankAccount,
          status: null,
          message: null,
        })),
      );
  }, [state]);

  const updateTradingArray = (i: number, status: string, message?: string) => {
    if (!batchArray) return;
    const temp = [...batchArray];
    temp[i].status = status;
    if (message) temp[i].message = message;
    setBatchArray(temp);
  };

  const onSubmit = () => {
    if (state === null || !batchArray) return;
    const temp = [...batchArray];
    setBatchArray(temp.map((v) => ({ ...v, status: 'pending' })));
    setCurrentIdx(0);
    setPaused(false);
  };

  useEffect(() => {
    let unamounted = false;
    if (
      !action ||
      !batchArray ||
      currentIdx === undefined ||
      batchArray[currentIdx] === undefined ||
      paused
    )
      return;
    const batchItem = batchArray[currentIdx];
    updateTradingArray(currentIdx, 'processing');
    runBatch(action, batchItem)
      .then(() => updateTradingArray(currentIdx, 'success'))
      .catch((e) => {
        updateTradingArray(currentIdx, 'fail', e);
        setHasError(true);
      })
      .finally(() => {
        if (!unamounted) setCurrentIdx(currentIdx + 1);
        if (currentIdx === batchArray.length - 1) setComplete(true);
      });

    return () => {
      unamounted = true;
    };
  }, [action, currentIdx, paused]);

  const onEdit = () => {
    if (action === 'trading' || action === 'edit')
      navigate(Page.TradingBatch, {
        replace: true,
        state: batchArray
          ?.filter((v) => v.status !== 'success')
          .map((v) => ({
            id: v.id,
            quote: v.quote,
            price: v.price,
            amount: v.amount,
            minTotal: v.minTotal,
            maxTotal: v.maxTotal,
            bankAccountId: v.bankAccountId,
          })),
      });
    else navigate(Page.MyTrade, { replace: true, state: 'DISPLAY_CHECKED' });
  };

  const onComplete = () => navigate(Page.MyTrade, { replace: true });
  const isTradingOrEdit = action === 'trading' || action === 'edit';

  return (
    <div>
      <div className="text-[27px] font-[600]">{heading}</div>
      <div className="mt-[30px] rounded-[12px] bg-white dark:bg-black-500">
        {isTradingOrEdit && (
          <>
            <div className="flex flex-col flex-wrap gap-[20px] px-[20px] pt-[25px] xs:flex-row xs:gap-[10px] xs:px-[45px] xs:pt-[40px]">
              <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
                <div className="text-grey-700 dark:text-grey-300">
                  {t('tradingConfirm.desc.tradeType')}
                </div>
                <div>{t('trading.desc.sell')}</div>
              </div>
              <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
                <div className="text-grey-700 dark:text-grey-300">
                  {t('tradingConfirm.desc.coinType')}
                </div>
                <div>{state?.base.toUpperCase()}</div>
              </div>
              <div className="flex justify-between xs:block xs:w-[calc((100%-20px)/3)]">
                <div className="text-grey-700 dark:text-grey-300">
                  {t('tradingConfirm.desc.currency')}
                </div>
                <div>{state?.quote.toUpperCase()}</div>
              </div>
            </div>
          </>
        )}
        <div
          className={classNames('mx-[15px] my-[30px] h-[1px] bg-transparent xs:mx-[40px]', {
            'dark:bg-dark-700': isTradingOrEdit,
            'bg-light-200': isTradingOrEdit,
          })}
        />
        {isBiggerThanMd ? (
          <Table action={action} batchArray={batchArray ?? []} base={state?.base ?? ''} />
        ) : (
          <Card action={action} batchArray={batchArray ?? []} base={state?.base ?? ''} />
        )}
        <div className="mx-[15px] mt-[55px] pb-[40px] xs:mx-[40px]">
          {currentIdx === undefined && (
            <div className="flex justify-between">
              <Button appearance="outlined" type="button" onClick={onEdit}>
                {t('act.cancel')}
              </Button>
              <Button onClick={onSubmit} type="button">
                {textSubmit}
              </Button>
            </div>
          )}
          {currentIdx !== undefined && !paused && !complete && (
            <Button appearance="outlined" type="button" onClick={() => setPaused(true)}>
              {t('tradingBatch.act.pause')}
            </Button>
          )}
          {paused && !complete && (
            <div className="relative">
              <div>
                <Button
                  appearance="outlined"
                  type="button"
                  onClick={() => {
                    setPaused(false);
                    setCurrentIdx(batchArray?.findIndex((v) => v.status === 'pending'));
                  }}
                >
                  {t('tradingBatch.act.continue')}
                </Button>
              </div>
              {action === 'trading' && (
                <Button className="mt-5" type="button" onClick={onEdit}>
                  {t('tradingBatch.act.edit')}
                </Button>
              )}
              <Button type="button" onClick={onComplete} className="absolute bottom-0 right-0">
                {t('tradingBatch.act.complete')}
              </Button>
            </div>
          )}
          {hasError && complete && (
            <div className="relative">
              {action === 'trading' && (
                <Button type="button" onClick={onEdit}>
                  {t('tradingBatch.act.edit')}
                </Button>
              )}
              <Button type="button" onClick={onComplete} className="absolute bottom-0 right-0">
                {t('tradingBatch.act.complete')}
              </Button>
            </div>
          )}
          {!hasError && complete && (
            <div className="text-right">
              <Button type="button" onClick={onComplete}>
                {t('tradingBatch.act.complete')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TradingBatchConfirm;
